import * as EasyMDE from "easymde"
import { Modal } from 'flowbite';

import I16 from './images/favicon-16x16.png';
import I32 from './images/favicon-32x32.png';
import I96 from './images/favicon-96x96.png';

document.addEventListener('alpine:init', () => {
    Alpine.data('activate_confirm_dialog', ()=> ({
        modal: null,
        username: "",
        activate: true,
        confirms: null,
        init() {
            this.modal = new Modal(this.$el, {
                placement: 'center',
                backdrop: 'dynamic',
                closable: 'true'
            });
            window.addEventListener('activate_confirm',(event) => {
                this.confirm(event.detail.el, event.detail.name, event.detail.activate);
            });
        },
        confirm(el, un, activate) {
            this.confirms = el;
            this.username = un;
            this.activate = activate;
            this.modal.show();
        },
        confirmed() {
            htmx.trigger(this.confirms, 'sm:confirmed');
            this.modal.hide();
        },
        cancel() {
            this.modal.hide();
        }
    }));
    Alpine.data('profile_bio_editor', () => ({
        init() {
            this.editor = new EasyMDE({
                element: this.$el,
                status: false,
                spellChecker: false,
                autoRefresh: { delay: 300 }
            });
            this.editor.codemirror.refresh();
        }
    }));
    Alpine.data('generic_modal', () => ({
        init() {
            this.modal = new Modal(this.$el, {
                placement: 'center',
                backdrop: 'dynamic',
                closable: 'true'
            });
        },
        show() {
            this.modal.show();
            htmx.trigger(this.$el,"modal_shown");
        },
        hide() {
            this.modal.hide();
            htmx.trigger(this.$el,"modal_hidden");
        }
    }));
})

function render_diff_time(rtf, td) {
    const secsT = Math.floor(td/1000);
    const secs = secsT % 60;
    const minsT = Math.floor(secsT/60);
    const mins = minsT % 60;
    const hoursT = Math.floor(minsT/60);
    const hours = hoursT % 60;

    var days = Math.floor(hoursT/24);
    if (days > 0 && hours > 12) {
        days += 1;
    }

    if (days > 0) {
        return rtf.format(days, 'day');
    }
    if (hours > 0) {
        return rtf.format(hours, 'hour');
    }
    if (mins > 0 && days === 0) {
        return rtf.format(mins, 'minute')
    }
    if (secs > 0 && days === 0 && h === 0) {
        return rtf.format(secs, 'second')
    }
    return "now";
}

function sm_update_timestamps() {
    const rtf = new Intl.RelativeTimeFormat('en', {
        style: 'long', numeric: 'auto'
    });

    const now = Date.now();
    for (const node of document.querySelectorAll("[data-sm-ts-delta]")) {
        const val = Date.parse(node.dataset.smTsDelta);
        if (val) {
            const td = val - now;
            const text = render_diff_time(rtf, td);
            node.innerText = text;
        }
    }
}

window.setInterval(sm_update_timestamps, 60000);

import 'htmx.org'
window.htmx = require('htmx.org')
